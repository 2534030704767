import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function Contact() {
  const [mailSent, setHasSentMail] = useState(false);
  const [from_name, setHName] = useState('');
  const [from_phone, setPhone] = useState('');
  const [error, setError] = useState(null);
  const [reply_to, setMail] = useState('');
  const [message, setMessage] = useState('');
  const sendEmail = (e) => {
    e.preventDefault();
    setError(null);
    var err = [];
    var ind = 0;
    //checking data
    if (from_name.match(/^ *$/) !== null)
      err[0] = "Votre nom doit avoir au moins 3 caractères.";
    if (isNaN(from_phone)) {
      ind = err.length;
      err[ind] = "Votre numéro de téléphone ne doit contenir que des chiffres."
    }
    if (message.match(/^ *$/) !== null) {
      ind = err.length;
      err[ind] = "Message incorrect."
    }
    console.log(err);
    if (err.length > 0) {
      setError(err);
      return false;
    }
    else {
      console.log(err);
      emailjs.send("service_b8su4kt", "template_nllcggq", {
        from_name,
        from_phone,
        reply_to,
        message
      }, 'user_BfjYp78QOmOYzhjbZtZrB')
        .then((result) => {
          setHasSentMail(true);
          document.getElementById("contact-form").reset();
          setHName('');
          setPhone('');
          setMessage('');
          setMail('');
        }, (error) => {
          console.log(error.text);
          setHasSentMail(false);
        });
    }


  }
  return (
    <div className='container main-body'>
      <h1 className='text-center pt-5'>Nous contacter</h1>
      <>
        <h5 className='text-center py-2'>N'hesitez pas à nous demander un devis</h5>
        <h5 className='text-center pb-5'>Nous reviendrons vers vous au plus vite !</h5>
      </>
      <div className="row">
        <div className="col-md-8">
          {error && <div>
            <div class="alert alert-danger alert-dismissible fade show" role="alert" onChange={setError(null)}>
              <ul>
                {error.map(function (d, idx) {
                  return (<li key={idx}>{d}</li>)
                })}
              </ul>
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>}
          <form id='contact-form' onSubmit={sendEmail}>
            <input className="form-control" type='text' value={from_name} name="from_name" minLength={2} maxLength={60} required placeholder="Nom..." onChange={(e) => setHName(e.target.value)} /><br />
            <input className="form-control" type='number' required value={from_phone} name="from_phone" placeholder="Phone..." onChange={(e) => setPhone(e.target.value)} /><br />
            <input className="form-control" type='email' value={reply_to} name="reply_to" required placeholder="E-mail..." onChange={(e) => setMail(e.target.value)} /><br />
            <textarea className="form-control" name="message" required value={message} placeholder="Message..." minLength={15} maxLength={3000} style={{ height: '150px' }} onChange={(e) => setMessage(e.target.value)}></textarea><br />
            <input className="btn btn-block btn-outline-secondary" type="submit" value="Envoyer" /><br /><br />
          </form>
          {mailSent && <div>
            <div class="alert alert-success alert-dismissible fade show" role="alert">
              Votre message a été envoyé, vous serez contacté par un de nos collaborateurs dans de bref délai.
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>}
        </div>
        <div className="col-md-4">
          <b>Customer service:</b> <br />
          E-mail: <a href="mailto:contact@agencecofa.com">contact@agencecofa.com</a><br />
          <br /><br />
          <b>Canada:</b><br />
          447 rue Monseigneur-Coderre<br />
          Saint- amable J0L1N0 QC, Canada<br />
          Phone: +1 514 264 4507 <br />


          <br /><br />
          <b>Cameroun:</b><br />
          <address>
            Boulevard de la republique, Akwa<br />
            Douala, Cameroun<br />
            Phone: <phone>+237 6 72 95 68 07</phone> <br />
          </address>


        </div>
      </div>
    </div>
  )
}

export default Contact
