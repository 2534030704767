import React from "react";
import { Carousel } from "react-responsive-carousel";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ img }) => (
  <Carousel autoPlay showThumbs={false} showArrows={false} showIndicators={false} showStatus={false}>
    <div>
      <img alt="" src={img} />
    </div>
  </Carousel>
);
