import React from 'react';
import Helmet from 'react-helmet';

function Excellence() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Excellence opérationnelle</title>
        <meta name="description" content="Bien souvent, nos clients disposent de tout ce dont ils ont besoin et sont simplement à la recherche de soutien pour passer à l'étape suivante. " />
        <meta name="og:title" content="Agence CoFa - Excellence opérationnelle" />
        <meta name="og:description" content="La transformation digitale et numérique de votre organisation est au coeur de vos priorités." />
      </Helmet>
      <div className='container main-body'>
        <div className='row justify-content-center'>
          <div className='col-md-8'>
            <div className='row justify-content-center py-5'>
              <div className='col text-center'><h1>Excellence opérationnelle</h1></div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center pb-5'>
                <img src='/images/vision-pc.jpg' alt='vision Agence CoFa' width='100%' />
              </div>
            </div>
            <div className='row justify-content-center py-3'>
              <div className='col'>
                <p className='h3 font-light'>Bien souvent, nos clients disposent de tout ce dont ils ont besoin et sont simplement à la recherche de soutien pour passer à l'étape suivante. </p>
                <p className='h3 font-light'>Avec notre cabinet de conseils, nous combinons nos recherches et nos compétences pour redéfinir votre optimisation opérationnelle, et ainsi, placer votre entreprise sur le devant de la scène. Nous sommes fiers de pouvoir vous aider à redessiner les contours de votre entreprise et à en améliorer la gestion. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Excellence
