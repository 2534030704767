import React from 'react';
import Helmet from 'react-helmet';

function Transformation() {
  return (
    <>
      <Helmet>
        <title> Agence CoFa - Transformation numérique</title>
        <meta name="description" content="La transformation digitale et numérique de votre organisation est au coeur de vos priorités." />
        <meta name="og:title" content="Agence CoFa - Transformation numérique" />
        <meta name="og:description" content="La transformation digitale et numérique de votre organisation est au coeur de vos priorités." />
      </Helmet>
      <div className='container main-body'>
        <div className='row justify-content-center'>
          <div className='col-md-8'>
            <div className='row justify-content-center py-5'>
              <div className='col text-center'><h1>Transformation numérique</h1></div>
            </div>
            <div className='row justify-content-center py-3'>
              <div className='col'>
                <p className='h3 font-light'>La transformation digitale et numérique de votre organisation est au coeur de vos priorités. Business Intelligence, Big Data, ERP, CRM ou MOA seront des termes qui n'auront plus aucuns secret pour vous.</p>
                <p className='h3 font-light py-3'>Ensemble, nous élaborerons  votre feuille de route afin de piloter au mieux vos projets.</p>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center'>
                <h2 className='pb-2'>Des solutions innovantes</h2>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center pb-4'>
                <img src='/images/digital-solutions.jpeg' alt='solutions Agence CoFa' width='100%' />
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center'>
                <h2 className='pb-2'>Audit de la situation digitale</h2>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center pb-4'>
                <img src='/images/audit-solutions.jpeg' alt='audit Agence CoFa' width='100%' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Transformation
