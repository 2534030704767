import React from 'react';
import Helmet from 'react-helmet';
import HeroContainer from '../../HeroContainer';

function Realestate() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Hôtellerie et immobilier</title>
        <meta name="description" content="Nous  accompagnons nos clients dans la transformation de leurs organisations immobilières grâce à des approches de pointe en matière de stratégie, de conception organisationnelle, de marketing, de ventes ..." />
        <meta name="og:title" content="Agence CoFa - Hôtellerie et immobilier" />
        <meta name="og:description" content="Nous  accompagnons nos clients dans la transformation de leurs organisations immobilières grâce à des approches de pointe en matière de stratégie, de conception organisationnelle, de marketing, de ventes ..." />
      </Helmet>
      <HeroContainer img="/images/estate-500.png" />
      <div className="row w-100 mx-0 justify-content-center">
        <div className="col-md-8">
          <p className="w-100 text-center my-4 home-title">Hôtellerie et immobilier</p>
          <p className="h4 font-light">Les sociétés immobilières poursuivent depuis longtemps une approche cohérente de la création de valeur : biens immobiliers de haute qualité, faible coût de construction ou d’entretien et consolidation.<br />Cependant, les changements technologiques et la façon dont les gens utilisent l'immobilier ont considérablement perturbé l'industrie. <br /><br />Aujourd'hui, pour effectuer des actions qui ont un impact réel, les responsables se concentrent sur l'expérience client, en exploitant la puissance des données et de la technologie et en innovant leurs modèles commerciaux.</p>
          <p className="h4 font-light my-5">Nous  accompagnons nos clients dans la transformation de leurs organisations immobilières grâce à des approches de pointe en matière de stratégie, de conception organisationnelle, de marketing, de ventes, de gestion des risques, d'optimisation des dépenses, d'approvisionnement et d'expérience client. <br />Nous aidons les entreprises à modifier et à diversifier leurs modèles commerciaux, à développer de nouvelles capacités et à se développer géographiquement.</p>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center my-5">
        <div className="mr-2">
          <a href="/clients/distribution-retail" className='btn btn-outline-dark'>
            <span className='text-gold'>Distribution et retail</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/clients/industrie-pme" className='btn btn-outline-dark'>
            <span className='text-gold'>Industrie et PME</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/clients/sante" className='btn btn-outline-dark'>
            <span className='text-gold'>Santé</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/clients/service-public" className='btn btn-outline-dark'>
            <span className='text-gold'>Service public</span>
          </a>
        </div>
      </div>
    </>
  )
}

export default Realestate
