import React, { useState } from "react";
import { Header, Nav, Link, SvgBox } from "./Styles";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const menuVariants = {
    opened: {
      top: 0,
    },
    closed: {
      top: "-93vh"
    }
  };

  const linkVariants = {
    opened: {
      opacity: 1,
      y: 50
    },
    closed: {
      opacity: 0,
      y: 0
    }
  };

  return (
    <div className='w-100'>
      <Header>
        <SvgBox
          className='w-100'
        >
          <nav className="navbar navbar-dark bg-black py-0">
            <a className="navbar-brand py-0" href="/">
              <img src='/logo.png' alt='Agence CoFa' height='80px' />
              <span className='text-white ml-4 d-none d-md-inline-block'>Nous dépassons vos attentes</span>
            </a>
            <button className="navbar-toggler rounded" type="button" onClick={() => setIsOpen(!isOpen)} aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </nav>
        </SvgBox>
      </Header>

      <Nav
        initial={false}
        variants={menuVariants}
        animate={isOpen ? "opened" : "closed"}
      >
        <Link variants={linkVariants} className='nav-link' href='/'>Accueil</Link>
        <Link variants={linkVariants} className='nav-link' href='/a-propos'>A Propos</Link>
        <Link variants={linkVariants} className='nav-link' href='/clients'>Nos clients</Link>
        <Link variants={linkVariants} className='nav-link' href='/services'>Nos services</Link>
        <Link variants={linkVariants} className='nav-link' href='/contact'>Contact</Link>
      </Nav>
    </div>
  );
}

export default Navbar
