import React from 'react';
import Helmet from 'react-helmet';
import HeroContainer from '../HeroContainer';

function Services() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Notre Expertise</title>
        <meta name="description" content="Agence CoFa met son expertise à la dispositions de ses partenaires et clients qui opèrent dans différents secteurs d'activités." />
        <meta name="og:title" content="Agence CoFa - Notre Expertise" />
        <meta name="og:description" content="Agence CoFa met son expertise à la dispositions de ses partenaires et clients qui opèrent dans différents secteurs d'activités." />
      </Helmet>
      <HeroContainer img="/images/strategy-500.png" />
      <div className="row w-100 mx-0 justify-content-center">
        <div className="col-md-10">
          <div className="row w-100 mx-0 my-5 justify-content-center">
            <div className="col-md-4 mx-0 px-0 d-none d-md-block">
              <div>
                <img alt="" src="/images/conseil-500.png" width="100%" height="290px" />
              </div>
            </div>
            <div className="col-md-8 mx-0 px-3" style={{ backgroundColor: "#A9A9A9" }}>
              <p className="w-100 text-center py-4 home-title">Stratégie, gouvernance et organisation</p>
              <p>Nous aidons nos clients à mettre place à des modèles d'organisation adaptés à leurs ambitions tout en faisant évoluer leurs ressources humaines...</p>
              <div className="w-100 text-right">
                <a href="/services/strategie" className="btn btn-danger">Découvrir</a>
              </div>
            </div>
          </div>
          <div className="row w-100 mx-0 my-5 justify-content-center">
            <div className="col-md-8 mx-0 px-3" style={{ backgroundColor: "#696969" }} >
              <p className="w-100 text-center py-4 text-white home-title">Stratégie numérique et création de la valeur</p>
              <p className="text-white">Mettre en place de stratégies numérique pour s'adapter à la croissance rapide et l'èvolution constante des services numériques ces dernières années...</p>
              <a href="/services/numerique" className="btn btn-danger">Découvrir</a>
            </div>
            <div className="col-md-4 mx-0 px-0 d-none d-md-block">
              <div>
                <img alt="" src="/images/marketing-500.png" width="100%" height="290px" />
              </div>
            </div>
          </div>
          <div className="row w-100 mx-0 my-5 justify-content-center">
            <div className="col-md-4 mx-0 px-0 d-none d-md-block">
              <div>
                <img alt="" src="/images/tribunal-500.png" width="100%" height="290px" />
              </div>
            </div>
            <div className="col-md-8 mx-0 px-3" style={{ backgroundColor: "#708090" }}>
              <p className="w-100 text-center py-4 text-white home-title">Conseil et soutien juridique complet</p>
              <p className="text-white">Notre équipe d'avocats est pleinement consciente des défis auxquels sont confrontés les différents acteurs de la société et de l'importance de s'adapter ...</p>
              <div className="w-100 text-right">
                <a href="/services/legale" className="btn btn-danger">Découvrir</a>
              </div>
            </div>
          </div>
          <div className="row w-100 mx-0 my-5 justify-content-center">
            <div className="col-md-8 mx-0 px-3" style={{ backgroundColor: "#2F4F4F" }} >
              <p className="w-100 text-center py-4 text-white home-title">Logistique, transport et infrastructures</p>
              <p className="text-white">L'économie dépend de la connexion voies (routes) à travers lesquels les personnes et les marchandises se déplacent à travers le ciel, les routes, les rails ...</p>
              <a href="/services/logistique" className="btn btn-danger">Découvrir</a>
            </div>
            <div className="col-md-4 mx-0 px-0 d-none d-md-block">
              <div>
                <img alt="" src="/images/logistique-500.png" width="100%" height="290px" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Services
