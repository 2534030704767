import React from 'react';
import Helmet from 'react-helmet';
import HeroContainer from '../../HeroContainer';

function Industry() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Industrie et PME</title>
        <meta name="description" content="Agence CoFa et son réseau de partenaire accompagne ses clients d’une part sur le volet stratégique dans la définition de leur business model et d’autre part dans la mise en œuvre des axes de développement ..." />
        <meta name="og:title" content="Agence CoFa - Industrie et PME" />
        <meta name="og:description" content="Agence CoFa et son réseau de partenaire accompagne ses clients d’une part sur le volet stratégique dans la définition de leur business model et d’autre part dans la mise en œuvre des axes de développement ..." />
      </Helmet>
      <HeroContainer img="/images/industry-500.png" />
      <div className="row w-100 mx-0 justify-content-center">
        <div className="col-md-8">
          <p className="w-100 text-center my-4 home-title">Industrie et PME</p>
          <p className="h4 font-light">Face à l’essor des nouvelles technologies – <span className="font-weight-bold">Big Data, Blockchain, Internet of Things, Intelligence Artificielle </span>–  tous les acteurs de ce secteur sont confrontés à de nouveaux enjeux : la digitalisation des réseaux et de la relation client, la prise en compte des énergies renouvelables dans leur business model etc.</p>
          <p className="h4 my-5 font-light">Agence CoFa et son réseau de partenaire accompagne ses clients d’une part sur le volet stratégique dans la définition de leur business model et d’autre part dans la mise en œuvre des axes de développement. L'objectif est de piloter les projets complexes – organisationnels, technologiques et sociétaux – générés par leurs enjeux.</p>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center my-5">
        <div className="mr-2">
          <a href="/clients/distribution-retail" className='btn btn-outline-dark'>
            <span className='text-gold'>Distribution et retail</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/clients/industrie-pme" className='btn btn-outline-dark'>
            <span className='text-gold'>Industrie et PME</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/clients/real-estate" className='btn btn-outline-dark'>
            <span className='text-gold'>Hôtellerie et immobilier</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/clients/service-public" className='btn btn-outline-dark'>
            <span className='text-gold'>Service public</span>
          </a>
        </div>
      </div>
    </>
  )
}

export default Industry
