import React from 'react';
import Helmet from 'react-helmet';
import HeroContainer from '../HeroContainer';

function About() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - S'engager pour votre réussite</title>
        <meta name="description" content="Cabinet de conseil en stratégie et en organisation créé en 2017,  Agence CoFa accompagne ses clients dans leurs projets de création, d'innovation, d'amélioration et de transformation..." />
        <meta name="og:title" content="Agence CoFa - S'engager pour votre réussite" />
        <meta name="og:description" content="Cabinet de conseil en stratégie et en organisation créé en 2017,  Agence CoFa accompagne ses clients dans leurs projets de création, d'innovation, d'amélioration et de transformation..." />
      </Helmet>
      <HeroContainer img="/images/consulting.png" />
      <div className="row w-100 mx-0 justify-content-center">
        <div className="col-md-8">
          <p className="w-100 text-center my-4 home-title">Agence CoFa</p>
          <p className="mb-3 h3 font-light">Cabinet de conseil en stratégie et en organisation créé en 2017,  <span className="font-weight-bold">Agence CoFa</span> accompagne ses clients dans leurs projets de création, d'innovation, d'amélioration et de transformation. <br /><br /> <span className='font-weight-bold'>Notre motivation :</span> s'engager à vos côtés pour améliorer durablement la performance opérationnelle de votre entreprise.</p>
          <p className="my-4 h3 font-light">Nous aidons nos clients à décliner leur vision en actions stratégiques; Nous mettons en place des stratégies marketing et commerciales pour consolider leur positions et développer leurs ventes sur de nouveaux marchés.</p>
        </div>
      </div>
      <div className="row w-100 mx-0 justify-content-center">
        <div className='col-md-8'>
          <div className="row w-100 mx-0 justify-content-center">
            <div className='col-md'>
              <div className='card h-100'>
                <div className='card-header bg-black text-gold'>
                  <p className='h3 font-weight-bold text-center'>Engagement</p>
                </div>
                <div className='card-body my-3 mx-0 px-1'>
                  <p className='card-text h4 font-light'>Nos consultants assurent une grande qualité d'exécution et une implication totale pour la réalisation de vos projets</p>
                </div>
              </div>
            </div>
            <div className='col-md'>
              <div className='card h-100'>
                <div className='card-header bg-black text-gold'>
                  <p className='h3 font-weight-bold text-center'>Actions</p>
                </div>
                <div className='card-body my-3 mx-0 px-1'>
                  <p className='card-text h4 font-light'>Nous combinons nos recherches et nos compétences pour redéfinir votre optimisation opérationnelle.</p>
                </div>
              </div>
            </div>
            <div className='col-md'>
              <div className='card h-100'>
                <div className='card-header bg-black text-gold'>
                  <p className='h3 font-weight-bold text-center'>Relation client</p>
                </div>
                <div className='card-body my-3 mx-0 px-1'>
                  <p className='card-text h4 font-light'>Nous allions expertise-conseil et les qualités nécessaires au développement d'une relation client toujours harmonieuse.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
