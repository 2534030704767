import React from 'react';
import { Link } from "./Styles";

function Footer() {
  const y = () => { return new Date().getFullYear(); }
  return (
    <div className="row w-100 mx-0">
      <div className='row w-100 mt-3 mx-0 pb-4 justify-content-md-center bg-light'>
        <div className='col-md-8'>
          <div className='row w-100 justify-content-center'>
            <h3>Ils nous ont fait confiance</h3>
          </div>
          <div className='row w-100'>
            <div className='col-3'>
              <div class="card h-100">
                <div className='card-body'>
                  <img src="/images/vgn.png" class="d-block w-100 img-fluid" alt="VGN Consulting" />
                </div>
                <div class="card-footer d-none d-md-block">
                  VGN Consulting
                </div>
              </div>
            </div>
            <div className='col-3'>
              <div class="card h-100">
                <div className='card-body'>
                  <img src="/images/sorele.jpeg" class="d-block w-100 img-fluid" alt="Sorele Cosmetics" />
                </div>
                <div class="card-footer d-none d-md-block">
                  Sorele Cosmetics
                </div>
              </div>
            </div>
            <div className='col-3'>
              <div class="card h-100">
                <div className='card-body'>
                  <img src="/images/finitura.png" class="d-block w-100 img-fluid" alt="Finitura by Syneha Faré" />
                </div>
                <div class="card-footer d-none d-md-block">
                  Sineha Faré
                </div>
              </div>
            </div>
            <div className='col-3'>
              <div class="card h-100">
                <div className='card-body'>
                  <img src="/images/makepe.png" class="d-block w-100 img-fluid" alt="Hotel Makepe Palace" />
                </div>
                <div class="card-footer d-none d-md-block">
                  Makepe Palace
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row w-100 text-center mx-0 pt-4 bg-black d-block d-md-none'>
        <div className='col'>
          <div>
            <Link className='btn btn-outline-light py-4' href='/contact'>Contactez-nous</Link>
          </div>
          <div>
            <Link href="mailto:contact@agencecofa.com">contact@agencecofa.com</Link>
          </div>
        </div>
      </div>
      <div className='row w-100 justify-content-around text-white mx-0 pt-4 bg-black'>
        <div className='col-sm-1 col-md-3 d-none d-md-block'>
          <div>
            <Link className='btn btn-outline-light py-4' href='/contact'>Contactez-nous</Link>
          </div>
          <div>
            <Link href="mailto:contact@agencecofa.com">contact@agencecofa.com</Link>
          </div>
        </div>
        <div className='col-sm-5 col-md-3'>
          <b className='mb-3'>Canada:</b>
          <address>
            447 rue Monseigneur-Coderre<br />
            Saint- amable J0L1N0 QC, Canada<br />
            Phone: <a href='tel:+15142644507'>+1 514 264 4507</a> <br />
          </address>
        </div>
        <div className='col-sm-5 col-md-3'>
          <b className='mb-3'>Cameroun:</b>
          <address>
            Boulevard de la republique, Akwa<br />
            Douala, Cameroun<br />
            Phone: <a href='tel:+237672956807'>+237 6 72 95 68 07</a> <br />
          </address>
        </div>
      </div>
      <div className='row w-100 text-white mx-0 pt-3 bg-black' id='contactez-nous'>
        <div className='w-100 text-center'>
          <small>Agence CoFa © {y()}</small>
        </div>
      </div>
    </div>
  );
}

export default Footer;
