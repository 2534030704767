import React from 'react';
import Helmet from 'react-helmet';
import HeroContainer from '../HeroContainer';

function Strategy() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Stratégie et organisation</title>
        <meta name="description" content="C'est cette vision de perfectionner la méthologie dans votre domaine qui nous pousse, nous motive à donner le meilleur pour optenir l'excellence." />
        <meta name="og:title" content="Agence CoFa - Stratégie et organisation" />
        <meta name="og:description" content="C'est cette vision de perfectionner la méthologie dans votre domaine qui nous pousse, nous motive à donner le meilleur pour optenir l'excellence." />
      </Helmet>
      <HeroContainer img="/images/strat-formalisme.jpg" />
      <div className='container main-body'>
        <div className='row justify-content-center'>
          <div className='col-md-8'>
            <div className='row justify-content-center pt-5 pb-3'>
              <div className='col text-center'><h1>Stratégie et organisation</h1></div>
            </div>
            <div className='row justify-content-center pb-4'>
              <div className='col'>
                <p className='h3 font-light'>Ce que sera votre entreprise dans 3 ou 5 ans, c'est le défi que vous lancez! <br /> Déclinez votre vision en diverses actions stratégiques (marketing, digitales, RH, et bien d'autres encore).</p>
                <p className='h3 font-light py-3'>La clé : laisser place à des modèles d'organisation adaptés à vos ambitions tout en faisant évoluer vos ressources humaines.</p>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center'>
                <h2 className='pb-2'>Mise en égalité des organisations</h2>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center pb-4'>
                <img src='/images/strat-1.jpg' alt='vision Agence CoFa' width='100%' />
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center'>
                <h2 className='pb-2'>Formalisme</h2>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center pb-4'>
                <img src='/images/strat-formalisme.jpg' alt='vision Agence CoFa' width='100%' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Strategy
