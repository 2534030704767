import React from 'react';
import Helmet from 'react-helmet';
import HeroContainer from '../../HeroContainer';

function Retail() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Distribution et retail</title>
        <meta name="description" content="Notre équipe d'avocats est pleinement consciente des défis auxquels sont confrontés les différents acteurs de la société et de l'importance de s'adapter ..." />
        <meta name="og:title" content="Agence CoFa - Distribution et retail" />
        <meta name="og:description" content="Notre équipe d'avocats est pleinement consciente des défis auxquels sont confrontés les différents acteurs de la société et de l'importance de s'adapter ..." />
      </Helmet>
      <HeroContainer img="/images/distribution-500.png" />
      <div className="row w-100 mx-0 justify-content-center">
        <div className="col-md-8">
          <p className="w-100 text-center my-4 home-title">Distribution et retail</p>
          <p className="h4 font-light">Les commerces ont besoin de plus qu'une bonne affaire pour réaliser une vente et fidéliser les clients. Une position gagnante nécessite une stratégie supérieure, un merchandising attrayant, des opérations efficaces, un marketing ciblé, une organisation solide et une technologie fiable.</p>
          <p className="h4 font-light my-5">Au cours des cinq dernières années, nous avons accompagné nos clients dans plusieurs missions. <br />Nous avons la flexibilité et l'expertise pour créer des solutions personnalisées pour nos clients, quelques soient les défis auxquels ils font face. <br />Nos consultants travaillent en étroite collaboration avec les clients et transmettent aux cadres et employés de nos clients, leur savoir-faire, renforçant aisni leurs compétences et leurs capacités.</p>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center my-5">
        <div className="mr-2">
          <a href="/clients/industrie-pme" className='btn btn-outline-dark'>
            <span className='text-gold'>Industrie et PME</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/clients/sante" className='btn btn-outline-dark'>
            <span className='text-gold'>Santé</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/clients/real-estate" className='btn btn-outline-dark'>
            <span className='text-gold'>Hôtellerie et immobilier</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/clients/service-public" className='btn btn-outline-dark'>
            <span className='text-gold'>Service public</span>
          </a>
        </div>
      </div>
    </>
  )
}

export default Retail
