import React from 'react';
import Helmet from 'react-helmet';
import Carousel from "../Carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Home() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Cabinet de conseil en stratégie, organisation et management</title>
        <meta name="description" content="Cabinet de conseil en stratégie et en organisation créé en 2017,  Agence CoFa accompagne ses clients dans leurs projets de création, d'innovation, d'amélioration et de transformation." />
        <meta name="og:title" content="Agence CoFa - Cabinet de conseil en stratégie, organisation et management" />
        <meta name="og:description" content="Cabinet de conseil en stratégie et en organisation créé en 2017,  Agence CoFa accompagne ses clients dans leurs projets de création, d'innovation, d'amélioration et de transformation." />
      </Helmet>
      <div className='w-100'>
        <Carousel />
      </div>
      <div className='row w-100 justify-content-center bg-secondary px-0 mx-0'>
        <div className='col-md-7 px-md-5 text-white'>
          <p className="home-title pt-5">Cabinet de conseil en stratégie et en organisation, de formation et conduite de changement</p>
          <p className='h3 font-light'>Créé en 2017,  Agence CoFa accompagne ses clients dans leurs projets de création, d'innovation, d'amélioration et de transformation.</p>
          <p className="h3 font-light py-3">Notre motivation : s'engager à vos côtés pour améliorer durablement la performance opérationnelle de votre entreprise. </p>
        </div>
        <div className='col-md-5 d-none d-md-block'>
          <div>
            <img alt="" src="/images/cofa-prod-500.png" width="100%" />
          </div>
        </div>
      </div>
      <div className='w-100 px-0 mx-0'>
        <div className='row w-100 justify-content-center mx-0'>
          <div className='col-md-10 px-0'>
            <div className='row w-100 mx-0 justify-content-center py-3'>
              <div className='col-md my-3'>
                <p className="home-section-title text-uppercase text-center">Secteurs d'activités</p>
              </div>
            </div>
            <div className='row w-100 mx-0 justify-content-center'>
              <div className='col-md-4 mb-3 px-md-1'>
                <div className='w-100'>
                  <img alt="" src="/images/retail.png" width="100%" />
                  <a href="/clients/distribution-retail" className='btn btn-dark btn-absolute'>
                    <span className='text-uppercase text-gold font-weight-bold'>Distribution et retail</span>
                  </a>
                </div>
              </div>
              <div className='col-md-4 mb-3 px-md-1'>
                <div>
                  <img alt="" src="/images/factory.jpeg" width="100%" />
                  <a href="/clients/industrie-pme" className='btn btn-dark btn-absolute'>
                    <span className='text-uppercase text-gold font-weight-bold'>Industrie et PME</span>
                  </a>
                </div>
              </div>
              <div className='col-md-4 mb-3 px-md-1'>
                <div>
                  <img alt="" src="/images/medical.jpeg" width="100%" />
                  <a href="/clients/sante" className='btn btn-dark btn-absolute'>
                    <span className='text-uppercase text-gold font-weight-bold'>Santé</span>
                  </a>
                </div>
              </div>
            </div>
            <div className='row w-100 mx-0 justify-content-center'>
              <div className='col-md-4 mb-3 px-md-1'>
                <div>
                  <img alt="" src="/images/buildings.png" width="100%" />
                  <a href="/clients/real-estate" className='btn btn-dark btn-absolute'>
                    <span className='text-uppercase text-gold font-weight-bold'>Hôtellerie et immobilier</span>
                  </a>
                </div>
              </div>
              <div className='col-md-4 mb-3 px-md-1'>
                <div>
                  <img alt="" src="/images/public.png" width="100%" />
                  <a href="/clients/service-public" className='btn btn-dark btn-absolute'>
                    <span className='text-uppercase text-gold font-weight-bold'>Service public</span>
                  </a>
                </div>
              </div>
            </div>
            <div className='row w-100 mx-0 justify-content-center py-3'>
              <p className="home-section-title text-uppercase">Expertises</p>
            </div>
          </div>
        </div>
      </div>
      <div className='row w-100 justify-content-center px-0 mx-0'>
        <div className='col-md my-3'>
          <div className='card h-100'>
            <img className='card-img-top' alt="" src="/images/strat-1.png" />
            <div className='card-body'>
              <p className='card-text'>Conseil en stratégie, gouvernance et organisation; conduite du changement</p>
              <a href="/services/strategie" className="btn btn-outline-danger">Voir +</a>
            </div>
          </div>
        </div>
        <div className='col-md my-3'>
          <div className='card h-100'>
            <img className='card-img-top' alt="" src="/images/digital-solutions.png" />
            <div className='card-body'>
              <p className='card-text'>Stratégie numérique et création de la valeur, Re-invention de la vente digitale</p>
              <a href="/services/numerique" className="btn btn-outline-danger">Voir +</a>
            </div>
          </div>
        </div>
        <div className='col-md my-3'>
          <div className='card h-100'>
            <img className='card-img-top' alt="" src="/images/law.png" />
            <div className='card-body'>
              <p className='card-text'>Conseil et soutien juridique complet sur les problèmes les plus complexes</p>
              <a href="/services/legale" className="btn btn-outline-danger">Voir +</a>
            </div>
          </div>
        </div>
        <div className='col-md my-3'>
          <div className='card h-100'>
            <img className='card-img-top' alt="" src="/images/logistic-500.png" />
            <div className='card-body'>
              <p className='card-text'>Solution logistiques face aux problèmes des chaînes d'approvisionnement</p>
              <a href="/services/logistique" className="btn btn-outline-danger">Voir +</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
