import React from 'react';
import Helmet from 'react-helmet';

function Relation() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Relation client</title>
        <meta name="description" content="Positionner sa stratégie de marché et ses ambitions" />
        <meta name="og:title" content="Agence CoFa - Relation client" />
        <meta name="og:description" content="Positionner sa stratégie de marché et ses ambitions" />
      </Helmet>
      <div className='container main-body'>
        <div className='row justify-content-center'>
          <div className='col-md-8'>
            <div className='row justify-content-center py-5 bg-light '>
              <div className='col text-center'><h1>Relation client</h1></div>
            </div>
            <div className='row justify-content-center py-3'>
              <div className='col'>
                <h2 className='text-center '>L'objectif d'un service-client 5 étoiles</h2>
                <p className='h3 font-light'>Aujourd'hui, les entreprises comprennent de plus en plus que la différenciation par la qualité du produit/service n'est plus suffisante pour satisfaire un client.</p>
                <p className='h3 font-light pt-3'>Si vous souhaitez offrir une meilleure expérience à vos clients, les fidéliser et augmenter vos revenus, il faut mettre en pratique des techniques pertinentes et imparables. <br /><br />Nous vous aiderons à y parvenir!</p>
              </div>
            </div>
            <div className='row justify-content-center bg-light '>
              <div className='col'>
                <h2 className='py-3'>Positionner sa stratégie de marché et ses ambitions</h2>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center pb-4'>
                <img src='/images/handshake.jpeg' alt='relation client Agence CoFa' width='100%' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Relation
