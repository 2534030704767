import React from 'react';
import { Helmet } from 'react-helmet';
import HeroContainer from '../../HeroContainer';

function Strategie() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Stratégie, gouvernance et organisation</title>
        <meta name="description" content="Nous aidons nos clients à mettre place à des modèles d'organisation adaptés à leurs ambitions tout en faisant évoluer leurs ressources humaines..." />
        <meta name="og:title" content="Agence CoFa - Stratégie, gouvernance et organisation" />
        <meta name="og:description" content="Nous aidons nos clients à mettre place à des modèles d'organisation adaptés à leurs ambitions tout en faisant évoluer leurs ressources humaines..." />
      </Helmet>
      <HeroContainer img="/images/meeting-500.png" />
      <div className="row w-100 mx-0 justify-content-center">
        <div className="col-md-8">
          <p className="w-100 text-center my-4 home-title">Stratégie, gouvernance et organisation</p>
          <p className="h4 font-light">Dans gestion d'entreprise, on trouve fréquemment des responsables qui déploient des ressources à grande échelle sans aucune notion claire de leur stratégie. Et pourtant, la stratégie d'une entreprise est un ingrédient essentiel pour déterminer son avenir. Une stratégie valide générera de la croissance, des bénéfices ou tout autre objectif que les gestionnaires ont établi. Une stratégie inappropriée non seulement ne produira pas d'avantages, mais peut également entraîner un désastre.</p>
          <p className="h4 font-light my-4"><span className="font-weight-bold">Agence CoFa</span> collabore avec vous afin de transformer votre vision à court, moyen ou long terme en réalité à travers des actions stratégiques concrètes. Si nécessaire nous vous proposerons des déclinaisons de vos objectifs en fonction de notre expertise. Conscient du fait qu'il n'existe pas de "stratégie parfaite" qui conduit au succès si mise en place, mais que le succès d'une stratégie adoptée dépend de plusieurs facteurs. Nous définissons ensemble la meilleure stratégie à adopter en prenant en considération <span className="font-weight-bold">la cohérence interne, l'environnement, les ressources disponibles, le risque, le timing et la faisabilité</span>.</p>
          <ul className="h4 font-light mb-4">
            <li className="my-1">Nous relayons les ambitions des Directions en actions concrètes sur le terrain et délivrant des résultats tangibles.</li>
            <li className="my-1">Considérant chaque cas comme unique et en adaptant systématiquement auprès de vos équipes des méthodologies éprouvées par plusieurs années d'expérience.</li>
            <li className="my-1">Mobilisant des consultants capables d'appréhender la complexité de vos produits et services, de s'intégrer dans des projets nationaux ou internationaux, multi-métiers et à contenu technologique. </li>
            <li className="my-1">Innovant en permanence sur les outils et méthodes, tout en faisant la part des effets de mode, dans la perspective de vous faire bénéficier de nos meilleurs conseils aux entreprises et savoir-faire.</li>
          </ul>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center my-5">
        <div className="mr-2">
          <a href="/services/numerique" className='btn btn-outline-dark'>
            <span className='text-gold'>Stratégie numérique</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/services/legale" className='btn btn-outline-dark'>
            <span className='text-gold'>Conseil juridique</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/services/logistique" className='btn btn-outline-dark'>
            <span className='text-gold'>Logistique et transport</span>
          </a>
        </div>
      </div>
    </>
  )
}

export default Strategie
