import React from 'react';
import Helmet from 'react-helmet';
import HeroContainer from '../../HeroContainer';

function Legal() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Conseil et soutien juridique complet</title>
        <meta name="description" content="Notre équipe d'avocats est pleinement consciente des défis auxquels sont confrontés les différents acteurs de la société et de l'importance de s'adapter ..." />
        <meta name="og:title" content="Agence CoFa - Conseil et soutien juridique complet" />
        <meta name="og:description" content="Notre équipe d'avocats est pleinement consciente des défis auxquels sont confrontés les différents acteurs de la société et de l'importance de s'adapter ..." />
      </Helmet>
      <HeroContainer img="/images/lawyer-500.png" />
      <div className="row w-100 mx-0 justify-content-center">
        <div className="col-md-8">
          <p className="w-100 text-center my-4 home-title">Conseil et soutien juridique complet</p>
          <p className="h4 font-light">AgenceCofa fournit à ses clients les meilleurs conseils sur une large gamme de services. Notre équipe d'avocats est pleinement consciente des défis auxquels sont confrontés les différents acteurs de la société et de l'importance de s'adapter aux besoins en constante évolution des clients.</p>
          <p className="h4 font-light my-4">Nous offrons également un soutien juridique complet et une réponse d'urgence sur les problèmes les plus complexes. Notre équipe d'experts suit en permanence les évolutions législatives et réglementaires nationales et internationales afin d'accompagner au mieux nos clients dans leur mise en œuvre. Nos avocats ont de solides compétences techniques  ainsi qu'une compréhension approfondie, garantissant ainsi des solutions pratiques et rentables à nos clients.</p>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center my-5">
        <div className="mr-2">
          <a href="/services/strategie" className='btn btn-outline-dark'>
            <span className='text-gold'>Stratégie de gouvernance</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/services/numerique" className='btn btn-outline-dark'>
            <span className='text-gold'>Stratégie numérique</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/services/logistique" className='btn btn-outline-dark'>
            <span className='text-gold'>Logistique et transport</span>
          </a>
        </div>
      </div>
    </>
  )
}

export default Legal
