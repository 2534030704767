import React from 'react';
import Helmet from 'react-helmet';

function Change() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Conduite du changement</title>
        <meta name="description" content="C'est cette vision de perfectionner la méthologie dans votre domaine qui nous pousse, nous motive à donner le meilleur pour optenir l'excellence." />
        <meta name="og:title" content="Agence CoFa - Conduite du changement" />
        <meta name="og:description" content="C'est cette vision de perfectionner la méthologie dans votre domaine qui nous pousse, nous motive à donner le meilleur pour optenir l'excellence." />
      </Helmet>
      <div className='container main-body'>
        <div className='row justify-content-center'>
          <div className='col-md-8'>
            <div className='row justify-content-center py-5'>
              <div className='col text-center'><h1>Conduite du changement</h1></div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center pb-5'>
                <img src='/images/vision-pc.jpg' alt='vision Agence CoFa' width='100%' />
              </div>
            </div>
            <div className='row justify-content-center py-3'>
              <div className='col'>
                <h1 className='text-uppercase'>Engagement</h1>
                <p className='h3 bold'>Nos consultants assurent une grande qualité d'exécution et une implication totale pour la réalisation de vos projets</p>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center pb-4'>
                <img src='/images/vision-man.jpg' alt='vision Agence CoFa' width='100%' />
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col'>
                <h1 className='text-uppercase pb-3'>Le client</h1>
                <p className='h3 bold pb-5'>Nous allions expertise-conseil et les qualités nécessaires au développement d'une relation client toujours harmonieuse.</p>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center pb-4'>
                <img src='/images/vision-diagram.jpg' alt='vision Agence CoFa' width='100%' />
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col'>
                <h1 className='text-uppercase pb-3'>Pragmatisme et résultats</h1>
                <p className='h3 bold pb-5'>Notre équipe a l'obsession du résultat et de son appropriation par leurs clients</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Change
