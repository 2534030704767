import React from 'react';
import Helmet from 'react-helmet';
import HeroContainer from '../../HeroContainer';

function PublicService() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Secteur public</title>
        <meta name="description" content="Nous nous intéressons de manière globale aux administrations nationales et internationales. En effet, elles doivent relever les défis soulevés par la transformation numérique ..." />
        <meta name="og:title" content="Agence CoFa - Secteur public" />
        <meta name="og:description" content="Nous nous intéressons de manière globale aux administrations nationales et internationales. En effet, elles doivent relever les défis soulevés par la transformation numérique ..." />
      </Helmet>
      <HeroContainer img="/images/public-500.png" />
      <div className="row w-100 mx-0 justify-content-center">
        <div className="col-md-8">
          <p className="w-100 text-center py-4 home-title">Secteur public</p>
          <p className="h4 font-light">La transformation du secteur public est engagée depuis un certain temps. Et les <span className="font-weight-bold"> systèmes d’information</span> sont désormais au cœur de la performance des administrations publiques. Les administrations souhaitent se moderniser et répondre rapidement à leurs missions de prestations vis-à-vis des usagers et de la population de manière générale.</p>
          <p className="h4 font-light my-5">Agence CoFa a la conviction que les défis de la mondialisation élargissent ces enjeux au niveau international. C’est pourquoi, nous nous intéressons de manière globale aux administrations nationales et internationales. En effet, elles doivent relever les défis soulevés par la transformation numérique.</p>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center my-5">
        <div className="mr-2">
          <a href="/clients/distribution-retail" className='btn btn-outline-dark'>
            <span className='text-gold'>Distribution et retail</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/clients/industrie-pme" className='btn btn-outline-dark'>
            <span className='text-gold'>Industrie et PME</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/clients/sante" className='btn btn-outline-dark'>
            <span className='text-gold'>Santé</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/clients/real-estate" className='btn btn-outline-dark'>
            <span className='text-gold'>Hôtellerie et immobilier</span>
          </a>
        </div>
      </div>
    </>
  )
}

export default PublicService
