import React from 'react';
import Helmet from 'react-helmet';
import { Link } from "../Styles";

function Enterprise() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - S'engager pour votre réussite</title>
        <meta name="description" content="Agence CoFa aide les entreprises à atteindre leurs objectifs, mais plus encore, à les dépasser. Nous aimons ce que nous faisons et le faisons bien." />
        <meta name="og:title" content="Agence CoFa - S'engager pour votre réussite" />
        <meta name="og:description" content="Agence CoFa aide les entreprises à atteindre leurs objectifs, mais plus encore, à les dépasser. Nous aimons ce que nous faisons et le faisons bien." />
      </Helmet>
      <div className='container main-body'>
        <div className='row justify-content-center'>
          <div className='col-md-8'>
            <div className='row justify-content-center'>
              <div className='col text-center py-5'>
                <img src='/images/taking-note.jpeg' alt='vision Agence CoFa' width='100%' />
              </div>
            </div>
            <div className='row justify-content-center py-5'>
              <div className='col'>
                <h1 className='text-uppercase'>S'engager pour votre réussite</h1>
                <p className='h3 font-light'>Agence CoFa aide les entreprises à atteindre leurs objectifs, mais plus encore, à les dépasser. Notre expertise et notre savoir-faire sont deux outils qui nous permettent d'élaborer des stratégies sur-mesure pour nos clients. En travaillant ensemble, de l'élaboration de la stratégie jusqu'à sa mise en place, nous parvenons à relever tous les défis.</p>
              </div>
            </div>
            <div className='row justify-content-center py-5'>
              <div className='col text-center'><h1 className='text-uppercase'>Notre identité</h1></div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center pb-5'>
                <img src='/images/vision-pc.jpg' alt='vision Agence CoFa' width='100%' />
              </div>
            </div>
            <div className='row justify-content-center py-3'>
              <div className='col'>
                <h2 className='text-uppercase'>Engagement</h2>
                <p className='h3 font-light'>Nos consultants assurent une grande qualité d'exécution et une implication totale pour la réalisation de vos projets</p>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center pb-4'>
                <img src='/images/vision-man.jpg' alt='vision Agence CoFa' width='100%' />
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col'>
                <h2 className='text-uppercase pb-3'>Le client</h2>
                <p className='h3 font-light pb-5'>Nous allions expertise-conseil et les qualités nécessaires au développement d'une relation client toujours harmonieuse.</p>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center pb-4'>
                <img src='/images/vision-diagram.jpg' alt='vision Agence CoFa' width='100%' />
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col'>
                <h2 className='text-uppercase pb-3'>Pragmatisme et résultats</h2>
                <p className='h3 font-light pb-5'>Notre équipe a l'obsession du résultat et de son appropriation par leurs clients</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Enterprise
