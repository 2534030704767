import { motion } from "framer-motion";
import styled from "styled-components";

export const Header = styled.header`
  background: black;
  position: relative;
  display: flex;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: space-between;
  z-index: 1200;
`;

export const Nav = styled(motion.nav)`
  background-color: #343a40;
  min-height: 93vh;
  height:100vh;
  overflow-y: scroll;
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  padding-bottom: 1.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1100;
`;

export const Link = styled(motion.a)`
  color: white;
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
  cursor: pointer;
  text-decoration: none;
`;

export const SvgBox = styled(motion.div)``;
