import React from 'react';
import Helmet from 'react-helmet';
import HeroContainer from '../../HeroContainer';

function Health() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Santé</title>
        <meta name="description" content="Notre équipe accompagne ces entreprises dans l’analyse stratégique de leurs problématiques et dans la mise en œuvre de leurs projets structurants." />
        <meta name="og:title" content="Agence CoFa - Santé" />
        <meta name="og:description" content="Notre équipe accompagne ces entreprises dans l’analyse stratégique de leurs problématiques et dans la mise en œuvre de leurs projets structurants." />
      </Helmet>
      <HeroContainer img="/images/health-500.png" />
      <div className="row w-100 mx-0 justify-content-center">
        <div className="col-md-8">
          <p className="w-100 text-center my-4 home-title">santé</p>
          <p className="h4 font-light">Portée par la <span className="font-weight-bold">transformation digitale</span> et les <span className="font-weight-bold">nouvelles technologies</span>, les industries pharmaceutiques, de la santé et cosmétiques sont en perpétuel renouvellement. Ces entreprises doivent faire face à de nombreux défis, comme la capacité à innover et à améliorer la qualité des produits tout en optimisant les coûts de production et les délais de mise sur le marché. Elles doivent gérer les matières premières en passant par le contrôle qualité, la logistique ou l’optimisation de la fabrication; prendre en compte la réglementation qui a un impact concret dans leur travail quotidien.</p>
          <p className="h4 font-light mx-4">Agence Cofa accompagne ces entreprises dans l’analyse stratégique de leurs problématiques et dans la mise en œuvre de leurs projets structurants.</p>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center my-5">
        <div className="mr-2">
          <a href="/clients/distribution-retail" className='btn btn-outline-dark'>
            <span className='text-gold'>Distribution et retail</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/clients/industrie-pme" className='btn btn-outline-dark'>
            <span className='text-gold'>Industrie et PME</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/clients/real-estate" className='btn btn-outline-dark'>
            <span className='text-gold'>Hôtellerie et immobilier</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/clients/service-public" className='btn btn-outline-dark'>
            <span className='text-gold'>Service public</span>
          </a>
        </div>
      </div>
    </>
  )
}

export default Health
