import React from "react";
import { Carousel } from "react-responsive-carousel";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <Carousel autoPlay showThumbs={false} showArrows={false} infiniteLoop={true} interval={5000}>
    <div>
      <img alt="" src="/images/cofa-hand-500.png" />
      <p className="legend-text">Nous mettons notre savoir-faire à votre disposition pour vous aider à maximiser votre valeur, transformer votre business et développer de nouvelles capacités nécessaires pour gagner.</p>
    </div>
    <div>
      <img alt="" src="/images/cofa-pc-500.png" />
      <p className="legend-text">La qualité d'un produit ou d'un service n'est pas ce que le fournisseur y met. C'est ce que le client reçoit en retour et est disposé à payer pour le recevoir.</p>
    </div>
    <div>
      <img alt="" src="/images/cofa-note-500.png" />
      <p className="legend-text">Nous aidons les leaders de l'industrie à créer un changement qui compte, en nous concentrant sur la rentabilité à long terme.</p>
    </div>
    <div>
      <img alt="" src="/images/cofa-diag-500.png" />
      <p className="legend-text">"La valeur de la marque que vous construisez est votre atout le plus précieux. Ne laissez pas vos concurrents en profiter."</p>
    </div>
    <div>
      <img alt="" src="/images/cofa-strat-500.png" />
      <p className="legend-text">"Ignorance of the law excuses no man from practicing it."</p>
    </div>
  </Carousel>
);
