import React from 'react';
import Helmet from 'react-helmet';

function Conformite() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Conformité, contrôle et risques</title>
        <meta name="description" content="L'environnement des entreprises est en constante évolution depuis de nombreuses années, ainsi que la nature des risques..." />
        <meta name="og:title" content="Agence CoFa - Conformité, contrôle et risques" />
        <meta name="og:description" content="L'environnement des entreprises est en constante évolution depuis de nombreuses années, ainsi que la nature des risques..." />
      </Helmet>
      <div className='container main-body'>
        <div className='row justify-content-center'>
          <div className='col-md-8'>
            <div className='row justify-content-center py-5'>
              <div className='col text-center'><h1>Conformité, contrôle et risques</h1></div>
            </div>
            <div className='row justify-content-center'>
              <div className='col text-center pb-5'>
                <img src='/images/diagrams.jpeg' alt='Conformité Agence CoFa' width='100%' />
              </div>
            </div>
            <div className='row justify-content-center py-3'>
              <div className='col'>
                <p className='h3 font-light'>L'environnement des entreprises est en constante évolution depuis de nombreuses années. <br /> Face à ces nouveaux défis et à l'émergence de certains risques, la réponse réside dans l'adoption d'une gestion dynamique basée sur une réévaluation permanente et une adaptation des dispositifs de contrôle.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Conformite
