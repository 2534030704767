import React from 'react';
import { init } from 'emailjs-com';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion'

import Navbar from './components/Navbar';
import Footer from './components/Footer';

import Home from './components/pages/Home';
import Clients from './components/pages/Clients';
import About from './components/pages/About';
import Services from './components/pages/Services';
import Vision from './components/pages/Vision';
import Enterprise from './components/pages/Enterprise';
import Contact from './components/pages/Contact';
import Change from './components/pages/Change';
import Health from './components/pages/clients/Health';
import Industry from './components/pages/clients/Industry';
import PublicService from './components/pages/clients/PublicService';
import Realestate from './components/pages/clients/Realestate';
import Retail from './components/pages/clients/Retail';
import Strategie from './components/pages/services/Strategie';
import Numerique from './components/pages/services/Numerique';
import Legal from './components/pages/services/Legal';
import Logistique from './components/pages/services/Logistique';
import Excellence from './components/pages/Excellence';
import Conformite from './components/pages/Conformite';
import Expertises from './components/pages/Expertises';
import Identity from './components/pages/Identity';
import Relation from './components/pages/Relation';
import Strategy from './components/pages/Strategy';
import Transformation from './components/pages/Transformation';

function App() {
  init("user_BfjYp78QOmOYzhjbZtZrB");
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route
            render={({ location }) => (
              <AnimatePresence exitBeforeEnter initial={false}>
                <Switch location={location} key={location.pathname}>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/a-propos" component={About} />
                  <Route exact path="/clients" component={Clients} />
                  <Route exact path="/clients/sante" component={Health} />
                  <Route exact path="/clients/industrie-pme" component={Industry} />
                  <Route exact path="/clients/distribution-retail" component={Retail} />
                  <Route exact path="/clients/real-estate" component={Realestate} />
                  <Route exact path="/clients/service-public" component={PublicService} />
                  <Route exact path="/services" component={Services} />
                  <Route exact path="/services/strategie" component={Strategie} />
                  <Route exact path="/services/legale" component={Legal} />
                  <Route exact path="/services/logistique" component={Logistique} />
                  <Route exact path="/services/numerique" component={Numerique} />
                  <Route exact path="/notre-vision" component={Vision} />
                  <Route exact path="/l-entreprise" component={Enterprise} />
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/conduite-changement" component={Change} />
                  <Route exact path="/excellence-operationnelle" component={Excellence} />
                  <Route exact path="/expertises" component={Expertises} />
                  <Route exact path="/conformite-controle-risques" component={Conformite} />
                  <Route exact path="/notre-identite" component={Identity} />
                  <Route exact path="/relation-client" component={Relation} />
                  <Route exact path="/strategie-organisation" component={Strategy} />
                  <Route exact path="/transformation-numerique" component={Transformation} />
                </Switch>
              </AnimatePresence>
            )}
          />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
