import React from 'react';
import { Helmet } from 'react-helmet';
import HeroContainer from '../../HeroContainer';

function Numerique() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Stratégie numérique et création de la valeur</title>
        <meta name="description" content="Mettre en place de stratégies numérique pour s'adapter à la croissance rapide et l'èvolution constante des services numériques ces dernières années..." />
        <meta name="og:title" content="Agence CoFa - Stratégie numérique et création de la valeur" />
        <meta name="og:description" content="Mettre en place de stratégies numérique pour s'adapter à la croissance rapide et l'èvolution constante des services numériques ces dernières années..." />
      </Helmet>
      <HeroContainer img="/images/audit-solutions.jpeg" />
      <div className="row w-100 mx-0 justify-content-center">
        <div className="col-md-8">
          <p className="w-100 text-center my-4 home-title">Stratégie numérique et création de la valeur</p>
          <p className="h4 font-light">L’informatique a connu une croissance rapide des services numériques ces dernières années, tandis que les services informatiques traditionnels diminuent. Les principaux prestataires de services dans le monde IT ajustent leur stratégie, se réorganisent, réalisent des investissements ciblés et améliorent leur compétitivité pour construire et faire évoluer leurs services numériques et gérer efficacement le déclin de leurs portefeuilles traditionnels.</p>
          <p className="h4 my-4 font-light">Nous aidons les principaux fournisseurs de services informatiques et spécialistes de la transformation numérique dans leur stratégie numérique et leur développement commercial en identifiant et en donnant la priorité aux domaines de croissance, en mettant en place l'organisation et le modèle opérationnel.</p>
          <p className="w-100 text-center my-4 home-title">Nos actions:</p>
          <p className="h4 font-light pl-5 my-4"><span className="font-weight-bold">Stratégie numérique et création de la valeur:</span> redéfinition des stratégies de croissances à long terme à travers l’identification des “centres d’attention” et la mise en place de solutions qui sortent de l’ordinaire</p>
          <p className="h4 font-light pl-5 my-4"><span className="font-weight-bold">Re-inventer la vente digitale:</span> Stimulez la croissance en renforçant les capacités de vente, en exploitant les analyses utiles pour l'identification des opportunités et la prise de décision, et en personnalisant les activités de vente pour le numérique.</p>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center my-5">
        <div className="mr-2">
          <a href="/services/strategie" className='btn btn-outline-dark'>
            <span className='text-gold'>Stratégie de gouvernance</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/services/legale" className='btn btn-outline-dark'>
            <span className='text-gold'>Conseil juridique</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/services/logistique" className='btn btn-outline-dark'>
            <span className='text-gold'>Logistique et transport</span>
          </a>
        </div>
      </div>
    </>
  )
}

export default Numerique
