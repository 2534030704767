import React from 'react';
import Helmet from 'react-helmet';
import HeroContainer from '../../HeroContainer';

function Logistique() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Logistique, transport et infrastructures</title>
        <meta name="description" content="L'économie dépend de la connexion voies (routes) à travers lesquels les personnes et les marchandises se déplacent à travers le ciel, les routes, les rails ..." />
        <meta name="og:title" content="Agence CoFa - Conseil et soutien juridique complet" />
        <meta name="og:description" content="L'économie dépend de la connexion voies (routes) à travers lesquels les personnes et les marchandises se déplacent à travers le ciel, les routes, les rails ..." />
      </Helmet>
      <HeroContainer img="/images/transport-500.png" />
      <div className="row w-100 mx-0 justify-content-center">
        <div className="col-md-8">
          <p className="w-100 text-center py-4 home-title">Logistique, transport et infrastructures</p>
          <p className="h4 font-light">L'économie dépend de la connexion (voies ou routes) à travers lesquels les marchandises se déplacent à travers le ciel, les routes, les rails, les océans. La sophistication et la complexité croissante des secteurs du voyage, de la logistique et des infrastructures ont contribué à alimenter la croissance économique mondiale, mais elles ont également révélé des vulnérabilités.</p>
          <p className="h4 font-light mt-2 mb-4">Les leaders de l'industrie sont aujourd'hui confrontés à des défis sans précédent provoqués par des technologies en évolution rapide, une pandémie imprévue et une responsabilité accrue de maintenir les chaînes d'approvisionnement vitales opérationnelles. </p>
          <p className="h4 font-light my-4">Nous apportons une compréhension approfondie du fonctionnement des secteurs du voyage, de la logistique et des infrastructures, et notre vaste expérience, nos analyses avancées et notre réseau d'experts sans précédent nous donnent une capacité unique à transformer les menaces en opportunités. <br />Il existe des opportunités en période de transition, et nous permettons aux dirigeants de faire un effort supplémentaire pour se concentrer sur de nouveaux concepts, technologies et stratégies audacieux qui permettront de débloquer de nouveaux territoires, partenariats et sources de revenus potentiels.</p>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center my-5">
        <div className="mr-2">
          <a href="/services/strategie" className='btn btn-outline-dark'>
            <span className='text-gold'>Stratégie de gouvernance</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/services/numerique" className='btn btn-outline-dark'>
            <span className='text-gold'>Stratégie numérique</span>
          </a>
        </div>
        <div className="mr-2">
          <a href="/services/legale" className='btn btn-outline-dark'>
            <span className='text-gold'>Conseil juridique</span>
          </a>
        </div>
      </div>
    </>
  )
}

export default Logistique
