import React from 'react';
import Helmet from 'react-helmet';
import HeroContainer from '../HeroContainer';

function Clients() {
  return (
    <>
      <Helmet>
        <title>Agence CoFa - Secteurs d'activités</title>
        <meta name="description" content="Agence CoFa met son expertise à la dispositions de ses partenaires et clients qui opèrent dans différents secteurs d'activités." />
        <meta name="og:title" content="Agence CoFa - Secteurs d'activités" />
        <meta name="og:description" content="Agence CoFa met son expertise à la dispositions de ses partenaires et clients qui opèrent dans différents secteurs d'activités." />
      </Helmet>
      <HeroContainer img="/images/strat-formalisme.jpg" />
      <div className='w-100 px-0 mx-0'>
        <div className='row w-100 justify-content-center mx-0'>
          <div className='col-md-10 px-0'>
            <div className='row w-100 mx-0 justify-content-center py-3'>
              <div className='col-md'>
                <p className="home-section-title text-uppercase text-center">Secteurs d'activités</p>
              </div>
            </div>
            <div className='row w-100 mx-0 justify-content-center'>
              <div className='col-md-4 mb-3 px-md-1'>
                <div className='w-100'>
                  <img alt="" src="/images/retail.png" width="100%" />
                  <a href="/clients/distribution-retail" className='btn btn-dark btn-absolute'>
                    <span className='text-uppercase text-gold font-weight-bold'>Distribution et retail</span>
                  </a>
                </div>
              </div>
              <div className='col-md-4 mb-3 px-md-1'>
                <div>
                  <img alt="" src="/images/factory.jpeg" width="100%" />
                  <a href="/clients/industrie-pme" className='btn btn-dark btn-absolute'>
                    <span className='text-uppercase text-gold font-weight-bold'>Industrie et PME</span>
                  </a>
                </div>
              </div>
              <div className='col-md-4 mb-3 px-md-1'>
                <div>
                  <img alt="" src="/images/medical.jpeg" width="100%" />
                  <a href="/clients/sante" className='btn btn-dark btn-absolute'>
                    <span className='text-uppercase text-gold font-weight-bold'>Santé</span>
                  </a>
                </div>
              </div>
            </div>
            <div className='row w-100 mx-0 justify-content-center'>
              <div className='col-md-4 mb-3 px-md-1'>
                <div>
                  <img alt="" src="/images/buildings.png" width="100%" />
                  <a href="/clients/real-estate" className='btn btn-dark btn-absolute'>
                    <span className='text-uppercase text-gold font-weight-bold'>Hôtellerie et immobilier</span>
                  </a>
                </div>
              </div>
              <div className='col-md-4 mb-3 px-md-1'>
                <div>
                  <img alt="" src="/images/public.png" width="100%" />
                  <a href="/clients/service-public" className='btn btn-dark btn-absolute'>
                    <span className='text-uppercase text-gold font-weight-bold'>Service public</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Clients
